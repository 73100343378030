import { LoaderFunction, LoaderFunctionArgs, useParams } from 'react-router-dom';
import { ENV } from '../utils/contants';
import { getById, queryClient } from '../utils/requests';
import { CustomDate } from '@church/utilities';
import { FaCalendarDays, FaEnvelope, FaPhone } from 'react-icons/fa6';
import { useQuery } from '@tanstack/react-query';

export default function EventDetails() {


    const params = useParams();

    const { data } = useQuery({
    queryKey: ['events', params.slug],
    queryFn: ({ signal }) =>
      getById({ signal, url: `events/status/upcoming/${params.slug}` }),
    });

    const event = data?.data;

    return (
        <section className='my-20 py-8'>
            {event && (
                <>
                    <div className='md:max-w-7xl m-auto px-4 sm:px-8 bg-slate-50 rounded-lg shadow-sm p-8'>
                        <h1 className='my-8 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center'>
                            {event.title}
                        </h1>
                        <div className='flex justify-center'>
                            <FaCalendarDays
                                className='h-5 w-5 fill-slate-400 mr-4'
                                aria-hidden='true'
                            />
                            <p className='text-gray-500 mb-8'>
                                {`${CustomDate.formatCustomDate(
                                    event.startDate
                                )} - ${CustomDate.formatCustomDate(
                                    event.endDate
                                )}`}
                            </p>
                        </div>
                        {event && event.images?.length > 0 && (
                            <img
                                src={`${ENV.images}/${event.images[0].url}`}
                                alt={event.title}
                                className='rounded md:w-full md:h-full'
                                width={900}
                                height={100}
                            />
                        )}
                        <p className='mt-6 text-lg leading-8 text-gray-700'>
                            {event.description}
                        </p>
                    </div>
                    <div className='flex md:max-w-7xl m-auto px-4 sm:px-8 rounded-lg p-8'>
                        <div>
                            <h1 className='text-lg font-bold mb-4'>
                                Organizer
                            </h1>
                            <p className='text-gray-500 '>{event.organizer}</p>
                            {event.telephone && (
                                <div className='flex items-center my-4'>
                                    <FaPhone
                                        className='h-5 w-5 fill-slate-400 mr-4'
                                        aria-hidden='true'
                                    />
                                    <p className='text-gray-500'>
                                        {event.telephone}
                                    </p>
                                </div>
                            )}
                            <div className='flex items-center my-4'>
                                <FaEnvelope
                                    className='h-5 w-5 fill-slate-400 mr-4'
                                    aria-hidden='true'
                                />
                                <p className='text-gray-500'>{event.email}</p>
                            </div>
                        </div>
                        <div className='ml-8'>
                            <h1 className='text-lg font-bold mb-4'>Venue</h1>
                            <p className='text-gray-500'>{event.location}</p>
                        </div>
                    </div>
                </>
            )}
        </section>
    );
}

export async function loader({ params }: LoaderFunctionArgs): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['events', params.slug],
    queryFn: ({ signal }) =>
      getById({ signal, url: `events/status/upcoming/${params.slug}` }),
  });
}
